// src/FullPageScroll.js
import React, { useState } from 'react';
import '../css/FullPageScroll.css';
import "../css/Footer.css"



const Footer = ({ navigateToPage }) => {

    let date = new Date();
    let year = date.getFullYear();

    return (
        <div className="fullpage-section flex flexColumn footer-section">
            <div className='footer-upper flex flexRow'>
                <div className='footer-contact-info flex flexColumn'>
                    <p>BOURNE CONSTRUCTION</p>
                    <div className='footer-contact-inner flex flexColumn'>
                        <div className='phone-numbers flex flexRow'>
                            <a href="tel:0433549301">0433 549 301</a>
                            <p>|</p>
                            <a href="tel:0418507020">0418 507 020</a>
                        </div>

                        <a href="mailto:info@bourneconstruction.com.au">info@bourneconstruction.com.au</a>
                        <a href="https://maps.app.goo.gl/sKZMxUvbCgPKeHH27" target='_blank'>20 Honour Street<br />Craigieburn VIC 3064</a>
                        <p className='working-hours'><span className='wh-day'>Mon - Fri:</span> 8.00 AM – 5.00 PM <span className='wh-middle'>|</span> <span className='wh-day'>Sat:</span> 9.00 AM – 12.00 PM</p>
                    </div>
                </div>

                <iframe className='map-iframe' width="900" height="300" frameborder="" scrolling="no" marginheight="0" marginwidth="0" id="gmap_canvas" src="https://maps.google.com/maps?width=520&amp;height=400&amp;hl=en&amp;q=20%20Honour%20Street%20Craigieburn+(Bourne%20Constructions)&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe><script type='text/javascript' src='https://embedmaps.com/google-maps-authorization/script.js?id=fc58d835312e2c784a9fcfcdc4ed01db67023c8d'></script>


            </div>
            <div className='hr-cont flex flexRow'>
                <hr className='footer-hr' />
                <p className='hr-p'>BUILDING BETTER FEATURES</p>
                <hr className='footer-hr' />
            </div>

            <div className='footer-bottom flex flexRow'>
                <div className='footer-contact flex flexColumn'>
                    <h3 className='footer-bottom-title'>Your Vision, Our Expertise!</h3>
                    <p className='footer-bottom-normal'>At Bourne Construction, we bring your ideas to life with precision and care. <a className='highlighted-span' href='/contact'>Contact Us</a> today to discuss your project and discover how we can build your future together.</p>
                </div>
                <div className='footer-links flex flexRow'>
                    <div className='footer-socials flex flexColumn'>
                        <p className='footer-bottom-title follow-us-title'>Socials</p>
                        <div className='footer-socials-inner flex flexRow'>
                            <a className='footer-bottom-link' href="https://www.instagram.com/bourneconstructions/" target='_blank'><img src={process.env.PUBLIC_URL + "/instagram.png"} alt="" /></a>
                            <a className='footer-bottom-link' href="https://www.linkedin.com/company/bourneconstructions/" target='_blank'><img src={process.env.PUBLIC_URL + "/linkedin.png"} alt="" /></a>
                            <a className='footer-bottom-link' href="https://x.com/bourneconstructions/" target='_blank'><img src={process.env.PUBLIC_URL + "/x.png"} alt="" /></a>
                            <a className='footer-bottom-link' href="https://www.facebook.com/bourneconstructions/" target='_blank'><img src={process.env.PUBLIC_URL + "/facebook.png"} alt="" /></a>
                        </div>
                    </div>
                    <div className='footer-sitemap flex flexColumn'>
                        <p className='footer-bottom-title'>Sitemap</p>
                        <a className='footer-bottom-link' onClick={() => navigateToPage(0)}>Home</a>
                        <a className='footer-bottom-link' onClick={() => navigateToPage(1)}>Showcase</a>
                        <a className='footer-bottom-link' onClick={() => navigateToPage(3)}>About</a>
                        <a className='footer-bottom-link' onClick={() => navigateToPage(4)}>Design</a>
                    </div>
                    <div className='footer-disclaimer flex flexColumn'>
                        <p className='footer-bottom-title'>Disclaimer</p>
                        <p className='footer-bottom-normal'>© Bourne Constructions {year}</p>
                        <a className="footer-bottom-link" href="https://ecgmedia.org" target='_blank'>Powered by <span className='ecg'>ECG</span> <span className='media'>MEDIA</span></a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;
