// src/App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import FullPageScroll from './components/FullPageScroll';
import LoadingScreen from './components/LoadingScreen';
import Contact from './components/Contact';
import "./css/responsive.css";

function App() {
  // Initialize isLoading based on localStorage data
  const [isLoading, setIsLoading] = useState(() => {
    const lastLoadingTime = localStorage.getItem('lastLoadingTime');
    const now = new Date().getTime();
    const tenMinutes = 10 * 60 * 1000;

    // If no last loading time is stored or it's been more than 10 minutes, show the loading screen
    if (!lastLoadingTime || now - parseInt(lastLoadingTime, 10) > tenMinutes) {
      return true;
    } else {
      return false;
    }
  });

  useEffect(() => {
    if (isLoading) {
      // Update localStorage with the current time when the loading screen is displayed
      const now = new Date().getTime();
      localStorage.setItem('lastLoadingTime', now.toString());

      // Set a timeout to hide the loading screen after the specified duration
      const timeout = setTimeout(() => {
        setIsLoading(false);
      }, 5500); // Duration of the loading screen

      // Cleanup timeout on component unmount
      return () => clearTimeout(timeout);
    }
  }, [isLoading]);

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <div>
                {isLoading && <LoadingScreen isLoading={isLoading} />}
                <FullPageScroll />
              </div>
            }
          />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
