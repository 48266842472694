// src/components/LoadingScreen.js
import React, { useState, useEffect } from 'react';
import '../css/LoadingScreen.css';

const LoadingScreen = ({ isLoading }) => {
    const [phase, setPhase] = useState(1);

    useEffect(() => {
        const phase1Duration = 700; // Phase 1 duration
        const phase2Duration = phase1Duration + 1500; // Phase 2 duration
        const phase3Duration = phase2Duration + 1500; // Phase 3 duration

        let beginning = document.getElementById("beginning");
        let phase1 = document.getElementById("phase1");
        let phase2 = document.getElementById("phase2");

        const timeout1 = setTimeout(() => {
            phase1.classList.add("phase1active")
            setPhase(2);
        }, phase1Duration);

        const timeout2 = setTimeout(() => {
            phase2.classList.add("phase2active")
            setPhase(3);
        }, phase2Duration);

        const timeout3 = setTimeout(() => {
            setPhase(4);
            beginning.classList.add("hide")
            phase1.classList.add("hide")
            phase2.classList.add("ended")
        }, phase3Duration);

        return () => {
            clearTimeout(timeout1);
            clearTimeout(timeout2);
            clearTimeout(timeout3);
        };
    }, [isLoading]);

    return (
        <div className={`loading-screen`}>
            <div id='beginning' className={`phase phase-one flex flexRow`}>

            </div>

            <div id='phase1' className={`phase phase-two flex flexRow`}>
                <span className='l1 l1-2'>bourne</span> <span className='l2 l2-2'>construction</span>
            </div>

            <div id='phase2' className={`phase phase-three flex flexRow`}>
                <span className='l1 l1-3'>bourne</span> <span className='l2 l2-3'>construction</span>
            </div>

        </div>
    );
};

export default LoadingScreen;
