import React from 'react';
import TextTransition, { presets } from 'react-text-transition';

const TEXTS = ['Efficient', 'Resistant', 'Durable', 'Flexible', "Innovative", "Robust", "Versatile", "Reliable", "Sustainable", "Economical"];

const TextAnimation = () => {
    const [index, setIndex] = React.useState(0);

    React.useEffect(() => {
        const intervalId = setInterval(
            () => setIndex((index) => index + 1),
            4000, // every 3 seconds
        );
        return () => clearTimeout(intervalId);
    }, []);



    return (
        <TextTransition className='animated-text' springConfig={presets.gentle}>{TEXTS[index % TEXTS.length]}</TextTransition>
    );
};

export default TextAnimation