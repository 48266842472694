// src/components/Landing.js
import React, { useEffect, useState } from 'react';
import '../css/Landing.css';
import TextAnimation from "../components/TextAnimation";
import Menu from './Menu';

const Landing = ({ navigateToPage }) => {
    const [videoLoaded, setVideoLoaded] = useState(false);

    useEffect(() => {
        const video = document.getElementById('fullpage-video');
        if (video) {
            video.addEventListener('loadeddata', () => {
                setVideoLoaded(true);
                video.play().catch(error => {
                    console.error("Video playback failed:", error);
                });
            });
        }
    }, []);

    return (
        <div className='fullpage-section landing flex flexColumn'>
            <video
                id='fullpage-video'
                className="fullpage-video"
                autoPlay
                src={`${process.env.PUBLIC_URL}/background.mp4`}
                loop
                playsInline
                muted
                poster={`${process.env.PUBLIC_URL}/house-2.jpg`}
            />

            {!videoLoaded && (
                <img id='fullpage-video'
                    className="fullpage-video"
                    src={`${process.env.PUBLIC_URL}/house4.jpg`}
                    style={{ objectPosition: "45% 50%" }}
                />
            )}
            <div className='navbar flex flexRow'>
                <h2>BOURNE <span className='brand-first'>CONSTRUCTIONS</span></h2>
                <div className='navbar-right flex flexRow'>
                    <a href="tel:0433549301" className='navbar-item phone'>0433 549 301</a>
                    <a href="mailto:info@bourneconstruction.com.au" className='navbar-item mail'>info@bourneconstruction.com.au</a>
                </div>
            </div>
            <div className='landing-bottom flex flexColumn'>
                <div className='landing-brief'><TextAnimation /> </div>
                <div className='landing-scroll flex flexRow' onClick={() => navigateToPage(1)}>
                    <video
                        className="scrolldown"
                        src={`${process.env.PUBLIC_URL}/scrolldown-animation.webm`}
                        autoPlay
                        loop
                        muted
                        playsInline
                    />
                    <p className='see-more'>SEE MORE</p>
                    <video
                        className="scrolldown scrolldown-right"
                        src={`${process.env.PUBLIC_URL}/scrolldown-animation.webm`}
                        autoPlay
                        loop
                        muted
                        playsInline
                    />
                </div>
            </div>
            <Menu navigateToPage={navigateToPage} />
        </div>
    );
};

export default Landing;
