import React, { useState } from 'react';
import "../css/Contact.css"
import { useNavigate } from 'react-router-dom';

import "../css/Services.css"


const Services = () => {


    return (
        <div className='ServicesPage flex flexColumn'>
            <div className='services-inner flex flexColumn'>
                <div className='offers flex flexColumn'>
                    <h3>OUR SERVICES</h3>
                    <div className='offers-inner flex flexRow'>
                        <div className='flex flexColumn'>
                            <img src={process.env.PUBLIC_URL + "/construction-icon-1-white.png"} alt="" />
                            <p>Building Construction</p>
                        </div>
                        <div className='flex flexColumn'>
                            <img src={process.env.PUBLIC_URL + "/construction-icon-2-white.png"} alt="" />
                            <p>Foundation Work</p>
                        </div>
                        <div className='flex flexColumn'>
                            <img src={process.env.PUBLIC_URL + "/construction-icon-3-white.png"} alt="" />
                            <p>Tear-downs & Haul-offs</p>
                        </div>
                        <div className='flex flexColumn'>
                            <img src={process.env.PUBLIC_URL + "/construction-icon-4-white.png"} alt="" />
                            <p>Site Management</p>
                        </div>
                    </div>
                </div>
                <div className='testimonials flex flexColumn'>
                    <h3>TESTIMONIALS</h3>
                    <div className='testimonials-inner flex flexRow'>
                        <div className='tm flex flexColumn'>
                            <h4 className='tm-name'>Liam S.</h4>
                            <p className='tm-de'>Great experience overall. The staff was friendly and knowledgeable, and the final product was better than I could have hoped for.</p>
                            <div className='tm-s flex flexRow'>
                                <img src={process.env.PUBLIC_URL + "/star-1.png"} alt="" />
                                <img src={process.env.PUBLIC_URL + "/star-1.png"} alt="" />
                                <img src={process.env.PUBLIC_URL + "/star-1.png"} alt="" />
                                <img src={process.env.PUBLIC_URL + "/star-1.png"} alt="" />
                                <img src={process.env.PUBLIC_URL + "/star-1.png"} alt="" />
                            </div>
                        </div>
                        <div className='tm flex flexColumn hide-1024'>
                            <h4 className='tm-name'>Isabella C.</h4>
                            <p className='tm-de'>From the moment I reached out, I felt valued and appreciated. The team is friendly, attentive, and truly cares about their clients.</p>
                            <div className='tm-s flex flexRow'>
                                <img src={process.env.PUBLIC_URL + "/star-1.png"} alt="" />
                                <img src={process.env.PUBLIC_URL + "/star-1.png"} alt="" />
                                <img src={process.env.PUBLIC_URL + "/star-1.png"} alt="" />
                                <img src={process.env.PUBLIC_URL + "/star-1.png"} alt="" />
                                <img className='tm-n' src={process.env.PUBLIC_URL + "/star-2.png"} alt="" />
                            </div>
                        </div>
                        <div className='tm flex flexColumn'>
                            <h4 className='tm-name'>Zoe E.</h4>
                            <p className='tm-de'>I've been a loyal customer for years, and I've never been disappointed. The quality and service are consistently excellent.</p>
                            <div className='tm-s flex flexRow'>
                                <img src={process.env.PUBLIC_URL + "/star-1.png"} alt="" />
                                <img src={process.env.PUBLIC_URL + "/star-1.png"} alt="" />
                                <img src={process.env.PUBLIC_URL + "/star-1.png"} alt="" />
                                <img src={process.env.PUBLIC_URL + "/star-1.png"} alt="" />
                                <img src={process.env.PUBLIC_URL + "/star-1.png"} alt="" />
                            </div>
                        </div>
                    </div>
                    <p className='tm-o'>Hearing from our customers is what drives us to strive for excellence every day. Your opinions and experiences are incredibly important to us, and we appreciate every piece of feedback that helps us serve you better.</p>
                </div>
            </div>
        </div>
    );
};

export default Services;
