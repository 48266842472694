// src/FullPageScroll.js
import React, { useState } from 'react';
import '../css/FullPageScroll.css';
import "../css/About.css"



const About = () => {


    return (
        <div className="fullpage-section flex flexRow about-section">
            <div className='about-upper flex flexRow'>
                <div className='about-follow flex flexColumn'>
                    <h3 className='about-mini-title'>Our socials</h3>
                    <div className='socials-box flex flexRow'>
                        <a href="https://www.instagram.com/bourneconstructions/" target='_blank'><img src={process.env.PUBLIC_URL + "/instagram.png"} alt="" /></a>
                        <a href="https://www.linkedin.com/company/bourneconstructions/" target='_blank'><img src={process.env.PUBLIC_URL + "/linkedin.png"} alt="" /></a>
                        <a href="https://x.com/bourneconstructions/" target='_blank'><img src={process.env.PUBLIC_URL + "/x.png"} alt="" /></a>
                        <a href="https://www.facebook.com/bourneconstructions/" target='_blank'><img src={process.env.PUBLIC_URL + "/facebook.png"} alt="" /></a>
                    </div>
                </div>
                <div className='about-brief flex flexColumn'>
                    <p className='about-mini-title'>Building Dreams, Crafting Reality.</p>
                    <p className='about-mini-bottom'>~ Where Quality Meets Innovation</p>
                </div>
            </div>
            <div className='about-main-cont flex flexRow'>
                <div className='about-inner about-first flex flexColumn justify'>
                    <div className='about-container about-c-1 flex flexColumn justify'>
                        <h3>ABOUT US</h3>
                        <p>
                            Bourne Construction was founded  in .. in 19… Since we started construction activities, we designed many important projects and gained a brand image as a leader in the sector. Bourne specializes in constructions of office projects, mall projects, hotels, energy facilities, plants, public buildings… Bourne aims to create an ideal area for society to live comfortably.


                        </p>
                        <p>
                            We are here to make your wishes come true. We are doing everything based on your wishes. Honesty, team spirit, transparency, innovation, ownership are our values. We will be the best business partner with the new technological tools.
                        </p>
                    </div>
                </div>
                <div className='about-inner about-second'>
                    <div className='about-container flex flexColumn justify'>
                        <img className='about-inner-img' src={process.env.PUBLIC_URL + "/house9.jpg"} alt="" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default About;
