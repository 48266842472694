import React, { useRef, useState } from 'react';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { Autoplay, Pagination, Navigation } from 'swiper/modules';

const ShowcaseSwiper = () => {
    const progressCircle = useRef(null);
    const progressContent = useRef(null);
    const onAutoplayTimeLeft = (s, time, progress) => {
        progressCircle.current.style.setProperty('--progress', 1 - progress);
        progressContent.current.textContent = `${Math.ceil(time / 1000)}s`;
    };


    return (
        <>
            <Swiper
                spaceBetween={30}
                centeredSlides={true}
                autoplay={{
                    delay: 5000,
                    disableOnInteraction: false,
                    pauseOnMouseEnter: true
                }}
                pagination={{
                    clickable: true,
                }}
                navigation={false}
                modules={[Autoplay, Pagination, Navigation]}
                onAutoplayTimeLeft={onAutoplayTimeLeft}
                className="mySwiper"
            >
                <SwiperSlide>
                    <div className="flex flexRow swiperGrid">
                        <a className='swiperImgContainer'>
                            <img className='swiper-img' src={process.env.PUBLIC_URL + `/house1.jpg`} alt="" />
                        </a>
                        <a className='swiperImgContainer'>
                            <img className='swiper-img' src={process.env.PUBLIC_URL + `/house2.jpg`} alt="" />
                        </a>
                        <div className='flex flexColumn swiperGridInner hide-1024'>
                            <a className='swiperImgContainer'>
                                <img className='swiper-img' src={process.env.PUBLIC_URL + `/house3.jpg`} alt="" style={{ objectPosition: "0 75%" }} />
                            </a>
                            <a className='swiperImgContainer'>
                                <img className='swiper-img' src={process.env.PUBLIC_URL + `/house4.jpg`} alt="" style={{ objectPosition: "0 65%" }} />
                            </a>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="flex flexRow swiperGrid">
                        <a className='swiperImgContainer swiper-container-full'>
                            <img className='swiper-img swiper-img-full-1' src={process.env.PUBLIC_URL + `/house5.jpg`} alt="" />
                        </a>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="flex flexRow swiperGrid swiperGridWarp">
                        <a className='swiperImgContainer'>
                            <img className='swiper-img swiper-img' src={process.env.PUBLIC_URL + `/house7.jpg`} alt="" style={{ objectPosition: "0 65%" }} />
                        </a>
                        <a className='swiperImgContainer'>
                            <img className='swiper-img swiper-img' src={process.env.PUBLIC_URL + `/house8.jpg`} alt="" style={{ objectPosition: "0 55%" }} />
                        </a>
                        <a className='swiperImgContainer'>
                            <img className='swiper-img swiper-img' src={process.env.PUBLIC_URL + `/house9.jpg`} alt="" />
                        </a>
                        <a className='swiperImgContainer'>
                            <img className='swiper-img swiper-img' src={process.env.PUBLIC_URL + `/house10.jpg`} alt="" style={{ objectPosition: "0 70%" }} />
                        </a>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="flex flexRow swiperGrid">
                        <a className='swiperImgContainer swiper-container-full'>
                            <img className='swiper-img swiper-img-full-1' src={process.env.PUBLIC_URL + `/house6.jpg`} alt="" style={{ objectPosition: "0 90%" }} />
                        </a>
                    </div>
                </SwiperSlide>
                <div className="autoplay-progress" slot="container-end">
                    <svg viewBox="0 0 48 48" ref={progressCircle}>
                        <circle cx="24" cy="24" r="20"></circle>
                    </svg>
                    <span ref={progressContent}></span>
                </div>
            </Swiper>
        </>
    );
}


export default ShowcaseSwiper;