// src/FullPageScroll.js
import React, { useState } from 'react';
import '../css/FullPageScroll.css';
import "../css/Interior.css"



const Interior = () => {


    return (
        <div className="fullpage-section flex flexColumn interior-section">
            <div className='interior-upper flex flexRow'>

                <p className='showcase-bigtitle showcase-title interior-bigtitle'>With over 15 years experience and real focus on customer satisfaction, you can rely on us for your next renovation, driveway sett or home repair. We provide a professional service for private and commercial customers.</p>
                <p className='showcase-smalltitle showcase-title interior-smalltitle'>We’ve Been Building<br/> For Over 15 Years</p>
            </div>
            <div className='interior-bottom flex'>
                <img src={process.env.PUBLIC_URL + "house6.jpg"} alt="" />
            </div>
        </div>
    );
};

export default Interior;
