// src/components/Menu.js
import React, { useState } from 'react';
import '../css/Menu.css'; // Import the CSS file
import { useNavigate, useLocation } from 'react-router-dom';

function Menu({ navigateToPage }) {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const navigate = useNavigate(); // Initialize the useNavigate hook
    const location = useLocation(); // Get the current location

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const handleMenuClick = (page) => {
        if (location.pathname === '/') {
            if (page === "contact") {
                navigate("/contact");
            } else {
                navigateToPage(page);
            }
        } else {
            if (page != "contact") {
                navigate("/");
            }
        }
        setIsMenuOpen(false); // Close menu after navigation
    };

    return (
        <>
            <button className="menu-button" onClick={toggleMenu}>
                {/* SVG Icons for Menu and Close */}
                <svg
                    className={`icon ${isMenuOpen ? 'icon-close' : 'icon-menu'}`}
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <line className="line line1" x1="3" y1="6" x2="21" y2="6" />
                    <line className="line line2" x1="3" y1="12" x2="21" y2="12" />
                    <line className="line line3" x1="3" y1="18" x2="21" y2="18" />
                </svg>
            </button>
            <div className={`full-page-menu flex flexColumn ${isMenuOpen ? 'open' : ''}`}>
                <h1 className='menu-title'>Bourne Constructions</h1>
                <div className='menu-inner flex flexRow'>
                    <div className='menu-first flex flexColumn'>
                        <a onClick={() => handleMenuClick(0)} className="menu-link link-underline">Home</a>
                        <a onClick={() => handleMenuClick(1)} className="menu-link link-underline">Showcase</a>
                        <a onClick={() => handleMenuClick(3)} className="menu-link link-underline">About Us</a>
                        <a onClick={() => handleMenuClick("contact")} className="menu-link link-underline">Contact</a>
                    </div>
                    <div className='menu-second flex flexColumn'>
                        <div className='menu-s menu-s-contact'>
                            <h4>CONTACT</h4>
                            <div className='menu-s-inner flex flexColumn'>
                                <a href="mailto:info@bourneconstruction.com.au">info@bourneconstruction.com.au</a>
                                <p><a href="tel:0433549301">0433 549 301</a> <span>|</span> <a href="tel:0418507020">0418 507 020</a></p>
                            </div>
                        </div>
                        <hr />
                        <div className='menu-s'>
                            <h4>WE ARE LOCATED AT</h4>
                            <div className='menu-s-inner flex flexColumn'>
                                <a href="https://maps.app.goo.gl/sKZMxUvbCgPKeHH27">20 Honour Street<br />Craigieburn VIC 3064</a>
                            </div>
                        </div>
                        <hr />
                        <div className='menu-s'>
                            <div className='menu-s-inner flex flexColumn'>
                                <a className="footer-bottom-link" href="https://ecgmedia.org" target='_blank' rel="noopener noreferrer">Powered by <span className='ecg'>ECG</span> <span className='media'>MEDIA</span></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Menu;
