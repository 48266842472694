// src/InnerFullPage.js
import React from 'react';
import '../css/Showcase.css';
import { useNavigate } from 'react-router-dom';

import ShowcaseSwiper from './ShowcaseSwiper';

const Showcase = ({ navigateToPage }) => {

  const navigate = useNavigate(); // Initialize the useNavigate hook

  const handleNavigate = () => {
    navigate('/contact'); // Navigate to the /contact route
  };

  return (
    <div id='showcase' className='flex flexColumn showcase-outer'>
      <div className='flex flexRow showcase-inner'>
        <h4 className='showcase-smalltitle showcase-title'>No Project Too Big Or Too Small</h4>
        <h2 className='showcase-bigtitle showcase-title'>We offer you the best and quality services; Tiling & Painting, Design & Build, Design team, Flooring, Building Permits, Fencing, Kitchen Fitouts, Landscaping, Solar Systems, Roof Tiling, Home Renovations, Concreting, Indoor Outdoor Electrical with the experienced and professional team for many years.</h2>
      </div>

      <div className="inner-fullpage-section showcase-page" style={{ height: "65vh" }}>
        <ShowcaseSwiper />
      </div>
      <div className='showcase-buttons flex flexRow'>
        <a onClick={handleNavigate} className='showcase-button underline contact'>CONTACT US</a>
      </div>
    </div>
  );
};

export default Showcase;
