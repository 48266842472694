import React, { useState } from 'react';
import "../css/Contact.css"
import { useNavigate } from 'react-router-dom';

import Footer from "./Footer"
import Menu from './Menu';

const Contact = ({ navigateToPage }) => {
    const [formData, setFormData] = useState({
        firstName: '',
        surname: '',
        email: '',
        phone: '',
        reason: '',
        message: '',
    });

    const navigate = useNavigate(); // Initialize the useNavigate hook

    const handleNavigate = (e) => {
        if (e.target.id == "home") {
            navigate(`/`); // Navigate to the /contact route
        } else {
            navigate(`/${e.target.id}`); // Navigate to the /contact route
        }
    };

    // Update state with form input changes
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    // Handle form submission
    const handleSubmit = async (event) => {
        event.preventDefault(); // Prevent default form submission behavior
        try {
            const response = await fetch('https://emailer.ecgmedia.org/contact-bourneconstructions', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                // Handle successful response
                alert("Thank you for contacting us! You will hear from us soon.")
                setFormData({
                    firstName: '',
                    surname: '',
                    email: '',
                    phone: '',
                    reason: '',
                    message: '',
                })
                console.log('Form submitted successfully');
            } else {
                // Handle errors from the server
                alert("Sending the contact form failed. Please try again!")
                console.error('Failed to submit form');
            }
        } catch (error) {
            // Handle network errors
            alert("Sending the contact form failed. Please try again!")
            console.error('Error submitting form:', error);
        }
    };

    return (
        <div className='ContactPage flex flexColumn'>
            <div className='contact-inner flex flexColumn'>
                <div className='contact-nav flex flexRow'>
                    <h2>BOURNE <span className='brand-first'>CONSTRUCTIONS</span></h2>
                    <div className='navbar-right flex flexRow'>
                        <a id='home' onClick={handleNavigate} className='navbar-item phone'>Home</a>
                    </div>
                </div>
                <div className='contact-main flex flexColumn'>
                    <div className='c-main-inner flex flexRow'>
                        <h1>Get In Touch</h1>
                        <p>Find out more about Bourne Constructions<br></br>
                            info@bourneconstruction.com.au<br></br>
                            0433 549 301 | 0418 507 020<br></br>
                            20 Honour Street Craigieburn VIC 3064</p>
                    </div>
                    <form onSubmit={handleSubmit} className='c-form flex flexColumn'>
                        <div className='c-form-inner underline-div flex flexRow'>
                            <label htmlFor="firstName">First Name*</label>
                            <input
                                id='firstName'
                                name='firstName'
                                type="text"
                                placeholder='e.g John'
                                value={formData.firstName}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className='c-form-inner underline-div flex flexRow'>
                            <label htmlFor="surname">Surname*</label>
                            <input
                                id='surname'
                                name='surname'
                                type="text"
                                placeholder='e.g Doe'
                                value={formData.surname}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className='c-form-inner underline-div flex flexRow'>
                            <label htmlFor="email">Email*</label>
                            <input
                                id='email'
                                name='email'
                                type="email"
                                placeholder='e.g john@example.com'
                                value={formData.email}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className='c-form-inner underline-div flex flexRow'>
                            <label htmlFor="phone">Phone*</label>
                            <input
                                id='phone'
                                name='phone'
                                type="text"
                                placeholder='e.g 0400 000 000'
                                value={formData.phone}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className='c-form-inner underline-div flex flexRow'>
                            <label htmlFor="reason">Interested in*</label>
                            <select
                                id="reason"
                                name="reason"
                                value={formData.reason}
                                onChange={handleInputChange}
                            >
                                <option value="" disabled>Select one...</option>
                                <option value="Knock-down rebuild">Knock-down rebuild</option>
                                <option value="Addition & Alteration">Addition & Alteration</option>
                                <option value="Existing architect plans">Existing architect plans</option>
                                <option value="General chat">General chat</option>
                            </select>
                        </div>
                        <div className='c-form-inner underline-div flex flexRow'>
                            <label htmlFor="message">Message</label>
                            <textarea
                                id='message'
                                name='message'
                                placeholder='e.g Tell us more...'
                                value={formData.message}
                                onChange={handleInputChange}
                            />
                        </div>
                        <button type="submit">Submit</button>
                    </form>
                </div>

            </div>
            <Menu navigateToPage={navigateToPage} />
            <Footer />
        </div>
    );
};

export default Contact;
