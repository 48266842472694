import React, { useState, useEffect } from 'react';
import ReactPageScroller from 'react-page-scroller';
import '../css/FullPageScroll.css';
import Landing from './Landing';
import Showcase from './Showcase';
import About from './About';
import Interior from './Interior';
import Footer from './Footer';
import Services from './Services';

const FullPageScroll = () => {
    const [currentPage, setCurrentPage] = useState(0);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Initial check for mobile

    const handlePageChange = (number) => {
        setCurrentPage(number);
    };

    const pageScrollEvent = (e) => {
        // let video = document.getElementById("fullpage-video");
        // if (e !== 0) {
        //     setTimeout(() => {
        //         video.pause();
        //     }, 1500);
        // } else {
        //     video.play();
        // }
    };

    const navigateToPage = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    useEffect(() => {
        // Function to update isMobile state based on window width
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768); // You can adjust the width threshold as needed
        };

        // Add event listener to update state on window resize
        window.addEventListener('resize', handleResize);

        // Cleanup the event listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <>
            {isMobile ? (
                // Render without ReactPageScroller on mobile devices
                <div>
                    <Landing navigateToPage={navigateToPage} />
                    <div className='showcase-container flex flexColumn'>
                        <Showcase navigateToPage={navigateToPage} />
                    </div>
                    <Services navigateToPage={navigateToPage} />
                    <About navigateToPage={navigateToPage} />
                    <Interior navigateToPage={navigateToPage} />
                    <Footer navigateToPage={navigateToPage} />
                </div>
            ) : (
                // Render with ReactPageScroller on desktop devices
                <ReactPageScroller
                    pageOnChange={handlePageChange}
                    customPageNumber={currentPage}
                >
                    <Landing navigateToPage={navigateToPage} />
                    <div className='showcase-container flex flexColumn'>
                        <Showcase navigateToPage={navigateToPage} />
                    </div>
                    <Services navigateToPage={navigateToPage} />
                    <About navigateToPage={navigateToPage} />
                    <Interior navigateToPage={navigateToPage} />
                    <Footer navigateToPage={navigateToPage} />
                </ReactPageScroller>
            )}
        </>
    );
};

export default FullPageScroll;
